import React, { useCallback, useRef, useState } from "react";
import { Container } from "../../atoms/grid";
import { Select } from "../../atoms/selects";
import { useInstitutionsContext } from "../../../contexts/institution/InstitutionsContext";
import { DateRangepicker } from "../../atoms/dates";
import dayjs, { Dayjs } from "dayjs";
import {
  DATA_RANGE_PERIOD,
  DATA_RANGE_PERIOD_LABEL,
  dataRangePeriods,
  TDataRangePeriod,
} from "../../../types/overview/enum";
import moment from "moment";
import { Gap } from "../../atoms/spaces";
import { Heading2, Heading4 } from "../../atoms/texts/heading";
import { Button, Checkbox } from "antd";
import { TGenerateReportFilter, TGenerateReportResponse } from "../../../types/report";
import { getAllQueryStatus, transactionTypes } from "../../../types/dispense";
import { generateReportApi } from "../../../api/report/reportApi";
import { handlePrivateApiError } from "../../../api/errorHandlers";
import { ICommonApiError } from "../../../api/apiRequest";
import { showErrorToastAction } from "../../../contexts/toast";
import { useAdminAuthContext } from "../../../contexts/admin/AuthContext";
import ReportView from "../common/ReportView";
import { Loading } from "../../atoms/displays";
import { useReactToPrint } from "react-to-print";
import { useOrganizationsContext } from "../../../contexts/organization/OrganizationsContext";

type TProps = {
  organizationId: string;
};

const OrganizationReport: React.FC<TProps> = ({ organizationId }) => {
  const { currentOrganization } = useOrganizationsContext();
  const { institutions, institutionsLoading } = useInstitutionsContext();
  const { logoutAdminApiAction } = useAdminAuthContext();

  const printRef = useRef<HTMLDivElement | null>(null);

  const [filters, setFilters] = useState<TGenerateReportFilter>({
    organizationId,
  });
  const [rangePeriods, setRangePeriods] = useState<TDataRangePeriod | undefined>();

  const [reportLoading, setReportLoading] = useState(false);
  const [report, setReport] = useState<TGenerateReportResponse>();
  const [reportFilters, setReportFilters] = useState<TGenerateReportFilter>();

  const setDateFilters = useCallback((from: Dayjs, to: Dayjs) => {
    setFilters((prev) => ({
      ...prev,
      max: false,
      dateStart: from.startOf("day").toDate(),
      dateEnd: to.endOf("day").toDate(),
    }));
  }, []);

  const onChangePeriod = useCallback(
    (n: string | undefined, k: number, val?: string) => {
      const value = val as unknown as TDataRangePeriod;

      setRangePeriods(value);
      const today = dayjs();

      switch (value) {
        case DATA_RANGE_PERIOD.LAST_30_DAYS:
          setDateFilters(today.subtract(30, "day"), today);
          break;

        case DATA_RANGE_PERIOD.LAST_3_MONTHS:
          setDateFilters(today.subtract(3, "month"), today);
          break;

        case DATA_RANGE_PERIOD.LAST_6_MONTHS:
          setDateFilters(today.subtract(6, "month"), today);
          break;

        case DATA_RANGE_PERIOD.LAST_YEAR:
          setDateFilters(today.subtract(1, "year"), today);
          break;

        case DATA_RANGE_PERIOD.LAST_2_YEARS:
          setDateFilters(today.subtract(2, "year"), today);
          break;

        case DATA_RANGE_PERIOD.THIS_MONTH:
          setDateFilters(today.startOf("month"), today);
          break;

        case DATA_RANGE_PERIOD.THIS_YEAR:
          setDateFilters(today.startOf("year"), today);
          break;

        case DATA_RANGE_PERIOD.MAXIMUM:
          setFilters((prev) => ({
            ...prev,
            max: true,
            dateStart: undefined,
            dateEnd: undefined,
          }));
          break;

        default:
          setFilters((prev) => ({
            ...prev,
            max: undefined,
            dateStart: undefined,
            dateEnd: undefined,
          }));
          break;
      }
    },
    [setDateFilters]
  );

  const handleGenerateReport = useCallback(async () => {
    try {
      setReportLoading(true);
      const { data } = await generateReportApi(filters);
      setReport(data);
      setReportFilters({ ...filters });
      setReportLoading(false);
    } catch (err) {
      setReportLoading(false);
      const { error, data } = handlePrivateApiError(err as ICommonApiError, logoutAdminApiAction);
      showErrorToastAction({
        message: data?.message || error || "Failed to fetch organization",
      });
    }
  }, [filters, logoutAdminApiAction]);

  const handlePrint = useReactToPrint({
    contentRef: printRef,
    documentTitle: `Report`,
  });

  return (
    <>
      <Heading2>Filters</Heading2>
      <Container display="flex" flexFlow="row" justifyContent="space-between" flexWrap="wrap">
        <Container display="flex" flexFlow="row" gap="1rem">
          <Container width="200px">
            <Select
              size="large"
              placeholder="Institution"
              allowClear
              loading={institutionsLoading}
              value={institutions.findIndex((inst) => inst._id === filters.institutionId)}
              options={institutions.map((inst) => ({ value: inst._id, label: inst.name }))}
              onChange={(n, k, v) => setFilters((prev) => ({ ...prev, institutionId: v }))}
            />
          </Container>
          <Container>
            <DateRangepicker
              size="large"
              allowClear={false}
              onChange={(n, nd, nds) => {
                setRangePeriods(undefined);
                if (nds) {
                  setFilters((prev) => ({
                    ...prev,
                    max: false,
                    dateStart: nds[0] ? dayjs(nds[0]).startOf("day").toDate() : undefined,
                    dateEnd: nds[1] ? dayjs(nds[1]).endOf("day").toDate() : undefined,
                  }));
                } else {
                  setFilters((prev) => ({
                    ...prev,
                    max: false,
                    dateStart: undefined,
                    dateEnd: undefined,
                  }));
                }
              }}
              values={
                !filters.dateStart && !filters.dateEnd
                  ? undefined
                  : [moment(filters.dateStart), moment(filters.dateEnd)]
              }
            />
          </Container>
          <Container width="200px">
            <Select
              size="large"
              placeholder="Period"
              value={dataRangePeriods.findIndex((d) => d === rangePeriods)}
              options={dataRangePeriods.map((rp) => ({
                label: DATA_RANGE_PERIOD_LABEL[rp],
                value: rp,
              }))}
              onChange={onChangePeriod}
              allowClear
            />
          </Container>
        </Container>
      </Container>
      <Gap height="1rem" />

      <Checkbox
        checked={filters.isShowSummary}
        onChange={(e) => setFilters((prev) => ({ ...prev, isShowSummary: e.target.checked }))}
      >
        <Heading2>Show summary</Heading2>
      </Checkbox>
      <Gap height="1rem" />

      <Checkbox
        checked={filters.isShowStockUpdates}
        onChange={(e) => setFilters((prev) => ({ ...prev, isShowStockUpdates: e.target.checked }))}
      >
        <Heading2>Stock / Machine updates</Heading2>
      </Checkbox>
      <Gap height="1rem" />

      <Container display="block">
        <Checkbox
          checked={filters.isShowDispenseUpdates}
          onChange={(e) =>
            setFilters((prev) => ({ ...prev, isShowDispenseUpdates: e.target.checked }))
          }
        >
          <Heading2>Dispense update details</Heading2>
        </Checkbox>
        {filters.isShowDispenseUpdates ? (
          <>
            <Gap height="1rem" />
            <Container width="50%" display="flex" gap="1rem" alignItems="center">
              <Select
                size="large"
                placeholder="Transaction type"
                value={transactionTypes.findIndex((tt) => tt === filters.dispenseTransactionType)}
                options={transactionTypes.map((tt) => ({
                  label: tt,
                  value: tt,
                }))}
                onChange={(n, k, v) =>
                  setFilters((prev) => ({ ...prev, dispenseTransactionType: v as any }))
                }
                allowClear
              />
              <Select
                size="large"
                placeholder="Status"
                value={getAllQueryStatus.findIndex((st) => st === filters.dispenseStatus)}
                options={getAllQueryStatus.map((st) => ({
                  label: st,
                  value: st,
                }))}
                onChange={(n, k, v) =>
                  setFilters((prev) => ({ ...prev, dispenseStatus: v as any }))
                }
                allowClear
              />
            </Container>
          </>
        ) : null}
      </Container>

      <Gap height="1rem" />

      <Container display="flex">
        <Button type="primary" size="large" loading={reportLoading} onClick={handleGenerateReport}>
          Generate Report
        </Button>
      </Container>

      <Gap height="2rem" />

      {reportLoading ? (
        <Loading size="100px" />
      ) : report ? (
        <div>
          <Container display="flex" alignItems="center" justifyContent="space-between">
            <Heading2>
              Report <Heading4 as="span">(Generated on: {dayjs().format("DD-MM-YYYY")})</Heading4>
            </Heading2>
            <Button size="large" onClick={() => handlePrint()}>
              Download
            </Button>
          </Container>
          <div ref={printRef}>
            <ReportView filters={reportFilters} organization={currentOrganization} {...report} />
          </div>
        </div>
      ) : null}
    </>
  );
};

export default OrganizationReport;
