import { Tabs } from "antd";
import React, { useEffect } from "react";
import { Loading } from "src/components/atoms/displays";
import Description from "src/components/organisms/common/Description";
import Institutions from "src/components/organisms/organizationAdminDashboard";
import Admins from "src/components/organisms/organizationAdminDashboard/Admins";
import { useAdminAuthContext } from "src/contexts/admin/AuthContext";
import { useInstitutionsContext } from "src/contexts/institution/InstitutionsContext";
import { useOrganizationsContext } from "src/contexts/organization/OrganizationsContext";
import styled from "styled-components";
import UnlockRFID from "../../organisms/organizationAdminDashboard/UnlockRFID";
import OrganizationAdminOverview from "../../organisms/organizationAdminDashboard/OrganizationAdminOverview";
import OrganizationReport from "../../organisms/organizationAdminDashboard/OrganizationReport";

const { TabPane } = Tabs;

const Container = styled.div`
  width: 100%;
`;

const PaddedTabPane = styled(TabPane)`
  padding-right: 1.5rem;
  padding-left: 1.5rem;
  padding-bottom: 3rem;
`;

const OrganizationAdminPage: React.FC = (props: any) => {
  const { adminAuth } = useAdminAuthContext();

  const {
    organizationAdmins,
    getAllOrganizationAdminApiAction,
    currentOrganization,
    getOrganizationByIdApiAction,
    unlockRFIDActionLoading,
  } = useOrganizationsContext();

  const { institutionsLoading, institutions, getAllInstitutionsApiAction } =
    useInstitutionsContext();

  const organizationId = props.match.params.organizationId;

  useEffect(() => {
    if (organizationId) {
      getAllInstitutionsApiAction(organizationId);
      getOrganizationByIdApiAction(organizationId);
    }
    if (adminAuth?.admin.type === "SUPER") {
      getAllOrganizationAdminApiAction(organizationId);
    }
  }, [
    organizationId,
    adminAuth,
    getOrganizationByIdApiAction,
    getAllOrganizationAdminApiAction,
    getAllInstitutionsApiAction,
  ]);

  return (
    <Container>
      <Description type="ORGANIZATION" organization={currentOrganization} />

      <Tabs type={"card"} defaultActiveKey={"1"} size={"large"}>
        <PaddedTabPane tab={"Overview"} key={"1"}>
          <OrganizationAdminOverview organizationId={organizationId} />
        </PaddedTabPane>

        <PaddedTabPane tab={"Institutions"} key={"2"}>
          {!institutionsLoading ? (
            <Institutions institutions={institutions} organizationId={organizationId} />
          ) : (
            <Loading size={"70px"} />
          )}
        </PaddedTabPane>

        <PaddedTabPane tab={"RFIDs to unlock"} key={"3"}>
          {!unlockRFIDActionLoading ? (
            <UnlockRFID organizationId={organizationId} rfids={currentOrganization?.unlockRFIDs} />
          ) : (
            <Loading size={"70px"} />
          )}
        </PaddedTabPane>

        <PaddedTabPane tab="Report" key="4">
          {currentOrganization ? (
            <OrganizationReport organizationId={currentOrganization._id} />
          ) : null}
        </PaddedTabPane>

        {adminAuth?.admin.type === "SUPER" ? (
          <PaddedTabPane tab={"Admins"} key={"5"}>
            <Admins
              admins={organizationAdmins}
              organizationId={organizationId}
              type="ORGANIZATION"
            />
          </PaddedTabPane>
        ) : null}
      </Tabs>
    </Container>
  );
};

export default OrganizationAdminPage;
