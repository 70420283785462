import * as yup from "yup";
import { yupSchemaWrapper } from "./validatorHelpers";

export const validatePaymentGateway = yupSchemaWrapper(
  yup.object().shape({
    username: yup.string().required("Username name is required"),
    password: yup.string().required("Password is required"),
    appKey: yup.string().required("App key is required"),
    appSecret: yup.string().required("App Secret is required"),
  })
);
