export function makeFormDataFromObject<T extends object>(object: T) {
  const formData = new FormData();
  Object.entries(object).forEach(([key, value]) => {
    if (value !== undefined && value !== null) {
      // Check if the value is an object
      if (typeof value === "object" && !Array.isArray(value)) {
        formData.append(key, JSON.stringify(value));
      } else {
        formData.append(key, value);
      }
    }
  });
  return formData;
}
