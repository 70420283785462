import React, { useEffect, useRef } from "react";
import { TGenerateReportFilter, TGenerateReportResponse } from "../../../types/report";
import { Container } from "../../atoms/grid";
import { DispenseStatusText, THead, THeader } from "../institutionAdminDashboard/common";
import { Heading2, Heading3 } from "../../atoms/texts/heading";
import { Writing1, Writing2, Writing3, Writing4 } from "../../atoms/texts/writing";
import { transactionTypes } from "../../../types/dispense";
import { Gap } from "../../atoms/spaces";
import dayjs from "dayjs";
import styled from "styled-components";
import { IOrganization } from "../../../types/organization";
import { IInstitution } from "../../../types/institution";
import LOGO from "../../../assets/images/diva.png";
import { useInstitutionsContext } from "../../../contexts/institution/InstitutionsContext";
import { useMachineContext } from "../../../contexts/machine/MachineContext";
import { useAdminAuthContext } from "../../../contexts/admin/AuthContext";

const ReportWrapper = styled.div`
  .header-container {
    display: none;
  }

  @media print {
    * {
      box-sizing: border-box;
    }
    margin: 0;
    padding: 0;
    @page {
      margin-top: 1.5cm;
      margin-bottom: 1.5cm;
      margin-left: 1cm;
      margin-right: 1cm;
    }

    .header-container {
      display: flex;
    }

    .logo-image {
      width: 4rem;
      height: 4rem;
      object-fit: contain;
    }
  }
`;

const FiltersViewContainer = styled.div`
  display: none;
  background-color: ${({ theme }) => theme.colors.backgroundColor};
  @media print {
    display: block;
    margin-top: 1rem;
  }
`;

const ReportTable = styled.table`
  width: 100%;
  border-collapse: collapse;

  & tbody * {
    font-size: 12px;
  }

  th,
  td {
    border: 1px solid ${({ theme }) => theme.colors.borderColor};
    padding: 8px;
    text-align: left;
  }

  @media print {
    &:not(.stock-summary) {
      th:nth-last-child(1),
      td:nth-last-child(1) {
        border-right-width: 2px;
      }
    }
  }

  th {
    background-color: ${({ theme }) => theme.colors.backgroundColor};
    font-weight: bold;
    font-size: 14px;
  }

  tbody tr:nth-child(odd) {
    background-color: #fafafa;
  }

  tbody tr:nth-child(even) {
    background-color: #ffffff;
  }

  tbody tr:hover {
    background-color: #eeeeee;
  }

  thead {
    display: table-header-group;
  }

  tfoot {
    display: table-footer-group;
  }

  table,
  th,
  td {
    page-break-inside: auto;
  }
`;

const StockMachineTableContainer = styled.div``;

const DispenseTableContainer = styled.div``;

const ReportFooter = styled.div`
  display: none;
  @media print {
    display: block;
    margin-top: 5cm;
  }
`;

type TProps = TGenerateReportResponse & {
  organization?: IOrganization;
  institution?: IInstitution;
  filters?: TGenerateReportFilter;
};

const ReportView: React.FC<TProps> = ({
  organization,
  institution,
  stockSummary,
  dispenseSummary,
  stocks,
  dispenses,
  filters,
}) => {
  const { adminAuth } = useAdminAuthContext();

  const { institutions } = useInstitutionsContext();
  const filteredInstitution = institutions.find((inst) => inst._id === filters?.institutionId);

  const { machines } = useMachineContext();
  const filteredMachine = machines.find((mac) => mac._id === filters?.machineId);

  const shouldPutRemainingStock = useRef(0);

  useEffect(() => {
    shouldPutRemainingStock.current = 0;
  }, [dispenseSummary]);

  return (
    <ReportWrapper>
      <Container
        className="header-container"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <img className="logo-image" src={institution?.logo ?? organization?.logo ?? LOGO} alt="" />

        <Container display="flex" flexFlow="column" alignItems="flex-end">
          {organization ? <Writing3>Organization: {organization.name}</Writing3> : null}
          {institution ? <Writing3>Institution: {institution.name}</Writing3> : null}
          <Writing4>Generated on: {dayjs().format("DD-MM-YYYY")}</Writing4>
        </Container>
      </Container>

      {filters ? (
        <FiltersViewContainer>
          <Container
            display="flex"
            alignItems="flex-start"
            justifyContent="space-between"
            padding="0.5rem 1rem"
          >
            <Container display="flex" flexFlow="column">
              {filters.dateStart ? (
                <Writing3>From: {dayjs(filters.dateStart).format("DD-MM-YYYY")}</Writing3>
              ) : null}
              {filters.dateEnd ? (
                <Writing3>To: {dayjs(filters.dateEnd).format("DD-MM-YYYY")}</Writing3>
              ) : null}
            </Container>

            <Container display="flex" flexFlow="column" alignItems="flex-end">
              {filteredInstitution ? (
                <Writing3>Institution: {filteredInstitution.name}</Writing3>
              ) : null}
              {filteredMachine ? <Writing3>Machine: {filteredMachine.physicalId}</Writing3> : null}
            </Container>
          </Container>
        </FiltersViewContainer>
      ) : null}

      <Gap height="1rem" />

      {filters?.isShowSummary ? (
        <>
          <Container display="flex" alignItems="flex-start" gap="1rem" flexWrap="wrap">
            <Container flex={1}>
              {stockSummary ? (
                <ReportTable className="stock-summary">
                  <thead>
                    <tr>
                      <th colSpan={2}>
                        <Heading2>Stock update summary</Heading2>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <Writing3>Total updates</Writing3>
                      </td>
                      <td>
                        <Writing3>{stockSummary.totalLogs}</Writing3>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Writing3>Opening stock</Writing3>
                      </td>
                      <td>
                        <Writing3>{stockSummary.openingValue}</Writing3>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Writing3>Closing stock</Writing3>
                      </td>
                      <td>
                        <Writing3>{stockSummary.closingValue}</Writing3>
                      </td>
                    </tr>
                  </tbody>
                </ReportTable>
              ) : null}
            </Container>

            {/* Dispense summary */}
            <Container flex={2}>
              {dispenseSummary ? (
                <ReportTable>
                  <thead>
                    <tr>
                      <th colSpan={4}>
                        <Heading2>Dispense summary</Heading2>
                      </th>
                    </tr>
                    <tr>
                      <th>
                        <Heading3>Type</Heading3>
                      </th>
                      <th>
                        <Heading3>Successful dispenses</Heading3>
                      </th>
                      <th>
                        <Heading3>Payment (BDT)</Heading3>
                      </th>
                      <th>
                        <Heading3>Remaining stock</Heading3>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {transactionTypes.map((type, index) => {
                      const trxType = dispenseSummary.transactionTypes.find((t) => t._id === type);
                      const totalRows = dispenseSummary.transactionTypes.filter(
                        (t) => t.totalSuccess
                      ).length;

                      if (!trxType?.totalSuccess) {
                        if (shouldPutRemainingStock.current >= 0) {
                          shouldPutRemainingStock.current++;
                        }
                        return null;
                      }

                      const shouldRowSpan = index === shouldPutRemainingStock.current;
                      shouldPutRemainingStock.current = -1;

                      return (
                        <tr key={type}>
                          <td>
                            <Writing3>{type}</Writing3>
                          </td>
                          <td>
                            <Writing3>{trxType?.totalSuccess ?? 0}</Writing3>
                          </td>
                          <td>
                            <Writing3>{trxType?.totalPaidAmount ?? 0}</Writing3>
                          </td>
                          {shouldRowSpan ? (
                            <td rowSpan={totalRows + 1}>
                              <Writing1>{dispenseSummary.remainingStock ?? 0}</Writing1>
                            </td>
                          ) : null}
                        </tr>
                      );
                    })}
                    <tr>
                      <td>
                        <Heading3>Total</Heading3>
                      </td>
                      <td colSpan={2}>
                        <Heading3>{dispenseSummary.totalSuccess}</Heading3>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <em>&#42;&nbsp;Price per pad is 10 Tk.</em>
                  </tfoot>
                </ReportTable>
              ) : null}
            </Container>
          </Container>
          <Gap height="1.5rem" />
        </>
      ) : null}

      {/* Stock updates */}
      <StockMachineTableContainer>
        {filters?.isShowStockUpdates && stocks ? (
          <>
            <ReportTable>
              <thead>
                <tr>
                  <th colSpan={3}>
                    <Heading2>Stock/Machine Updates</Heading2>
                  </th>
                </tr>
                <tr>
                  {institution ? (
                    <th>
                      <Heading3>Machine</Heading3>
                    </th>
                  ) : organization ? (
                    <th>
                      <Heading3>Institution</Heading3>
                    </th>
                  ) : (
                    <th>
                      <Heading3>Organization</Heading3>
                    </th>
                  )}

                  <th>
                    <Heading3>Event message</Heading3>
                  </th>
                  <th>
                    <Heading3>Assigned to</Heading3>
                  </th>
                </tr>
              </thead>
              <tbody>
                {stocks?.map((su) => (
                  <tr key={su._id}>
                    {institution ? (
                      <td>
                        <Writing4>{su.mpi}</Writing4>
                      </td>
                    ) : organization ? (
                      <td>
                        <Writing4>{su.in}</Writing4>
                      </td>
                    ) : (
                      <td>
                        <Writing4>{su.orn}</Writing4>
                      </td>
                    )}

                    <td>
                      <Writing4>{su.em}</Writing4>
                    </td>
                    <td>
                      <Writing4>{su.tat}</Writing4>
                    </td>
                  </tr>
                ))}
              </tbody>
            </ReportTable>
            <Gap height="1.5rem" />
          </>
        ) : null}
      </StockMachineTableContainer>

      <DispenseTableContainer>
        {filters?.isShowDispenseUpdates && dispenses ? (
          <>
            <THeader columns={1}>
              <THead>
                <Container
                  width="100%"
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Heading2>Dispense Logs</Heading2>

                  {filters ? (
                    <Container display="flex" flexFlow="column" alignItems="flex-end">
                      {filters.dispenseStatus ? (
                        <Writing4>Status: {filters.dispenseStatus}</Writing4>
                      ) : null}
                      {filters.dispenseTransactionType ? (
                        <Writing4>Transaction type: {filters.dispenseTransactionType}</Writing4>
                      ) : null}
                    </Container>
                  ) : null}
                </Container>
              </THead>
            </THeader>
            <ReportTable>
              <thead>
                <tr>
                  <th>
                    <Heading3>Machine</Heading3>
                  </th>
                  <th>
                    <Heading3>Payment type</Heading3>
                  </th>
                  <th>
                    <Heading3>RFID</Heading3>
                  </th>
                  <th>
                    <Heading3>Time</Heading3>
                  </th>
                  <th>
                    <Heading3>Status</Heading3>
                  </th>
                  <th>
                    <Heading3>Failure reason</Heading3>
                  </th>
                  <th>
                    <Heading3>Payment failure</Heading3>
                  </th>
                </tr>
              </thead>
              <tbody>
                {dispenses?.map((dispense) => (
                  <tr key={dispense._id}>
                    <td>
                      <Writing4>{dispense.machineId}</Writing4>
                    </td>
                    <td>
                      <Writing4>{dispense.transactionType}</Writing4>
                    </td>
                    <td>
                      <Writing4>{dispense.rfid}</Writing4>
                    </td>
                    <td>
                      <Writing4>
                        {dayjs(dispense.dispensedAt).format("YYYY-MM-DD hh:mm A")}
                      </Writing4>
                    </td>
                    <td>
                      <DispenseStatusText success={dispense.isSuccessful}>
                        {dispense.isSuccessful ? "Successful" : "Failed"}
                      </DispenseStatusText>
                    </td>
                    <td>
                      <Writing4>{dispense.failureReason}</Writing4>
                    </td>
                    <td>
                      <Writing4>{dispense.paymentFailure}</Writing4>
                    </td>
                  </tr>
                ))}
              </tbody>
            </ReportTable>
          </>
        ) : null}
      </DispenseTableContainer>

      <ReportFooter>
        <Container display="flex">
          <Writing2>Prepared by: {adminAuth?.admin.username}</Writing2>
        </Container>
      </ReportFooter>
    </ReportWrapper>
  );
};

export default ReportView;
