import React, { useEffect } from "react";
import styled from "styled-components";
import Organizations from "src/components/organisms/superAdminDashboard";
import { Tabs } from "antd";
import { useOrganizationsContext } from "src/contexts/organization/OrganizationsContext";
import { Loading } from "src/components/atoms/displays";
import SuperAdminOverview from "../../organisms/superAdminDashboard/SuperAdminOverview";
import SuperAdminReport from "../../organisms/superAdminDashboard/SuperAdminReport";

const { TabPane } = Tabs;

const Container = styled.div`
  width: 100%;
`;

const PaddedTabPane = styled(TabPane)`
  padding-right: 1.5rem;
  padding-left: 1.5rem;
  padding-bottom: 3rem;
`;

const SuperAdminPage: React.FC = () => {
  const { organizationsLoading, organizations, getAllOrganizationsApiAction } =
    useOrganizationsContext();

  useEffect(() => {
    getAllOrganizationsApiAction();
  }, [getAllOrganizationsApiAction]);

  return (
    <Container>
      <Tabs type={"card"} defaultActiveKey={"1"} size={"large"}>
        <PaddedTabPane tab={"Overview"} key={"1"}>
          <SuperAdminOverview />
        </PaddedTabPane>

        <PaddedTabPane tab={"Organizations"} key={"2"}>
          {!organizationsLoading ? (
            <Organizations organizations={organizations} />
          ) : (
            <Loading size={"70px"} />
          )}
        </PaddedTabPane>

        <PaddedTabPane tab="Report" key="3">
          <SuperAdminReport />
        </PaddedTabPane>
      </Tabs>
    </Container>
  );
};

export default SuperAdminPage;
