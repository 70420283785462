import React from "react";
import { Writing2 } from "../../../components/atoms/texts/writing";
import styled from "styled-components";

const MyWriting = styled(Writing2)`
  font-weight: normal;
`;

const PrimarySpan = styled.span`
  color: ${({ theme }) => theme.colors.primaryColor};
`;

const MainContainer = styled.footer`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.footerBgColor};
`;

const Container = styled.div`
  max-width: 1200px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 16px;
  margin: auto;
`;

const FooterComp: React.FC = () => {
  return (
    <MainContainer>
      <Container>
        <MyWriting>
          Made in
          <PrimarySpan> Headless Technologies</PrimarySpan> © {new Date().getFullYear()}
        </MyWriting>
      </Container>
    </MainContainer>
  );
};

const Footer = React.memo(FooterComp);

export { Footer };
