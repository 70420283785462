import styled from "styled-components";
import React from "react";

const MyDiv = styled.div<IProps>`
  display: ${({ display }) => display};
  flex-flow: ${({ flexFlow, display }) =>
    display ? (flexFlow === "column" ? "column" : "row") : null};
  flex-wrap: ${({ flexWrap }) => flexWrap};

  flex: ${({ flex }) => flex};
  padding: ${({ padding }) => padding || ""};
  margin: ${({ margin }) => margin || ""};

  width: ${({ width }) => width || ""};
  height: ${({ height }) => height || ""};

  justify-content: ${({ justifyContent }) => justifyContent || null};
  justify-items: ${({ justifyItems }) => justifyItems || null};

  align-content: ${({ alignContent }) => alignContent || null};
  align-items: ${({ alignItems }) => alignItems || null};

  gap: ${({ gap }) => gap || null};
`;

interface IProps {
  id?: string;
  display?: "flex" | "block";
  flexFlow?: string;
  flex?: number | string;
  padding?: string;
  margin?: string;
  width?: string;
  height?: string;
  background?: string;
  justifyContent?: string;
  justifyItems?: string;
  alignContent?: string;
  alignItems?: string;
  flexWrap?: string;
  gap?: string;
  onClick?: any;
  className?: string;
}

const Container: React.FC<IProps> = (props) => {
  return <MyDiv {...props}>{props.children}</MyDiv>;
};

export default Container;
