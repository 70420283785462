import React from "react";
import { Select, SelectProps } from "antd";
import { SizeType } from "antd/lib/config-provider/SizeContext";

const { Option } = Select;

interface IProps extends Omit<SelectProps<any>, "options" | "onChange"> {
  name?: string;
  placeholder?: string;
  size?: SizeType;
  options: string[] | { value: string; label: string }[];
  value?: number;
  disabled?: boolean;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onChange?: (name: string | undefined, key: number, value?: string) => void;
}

const SelectComponent: React.FC<IProps> = (props) => {
  const { name, size, options, value, onChange, onFocus, placeholder, disabled, ...rest } = props;
  const val =
    value !== undefined
      ? typeof options[value] === "string"
        ? options[value]
        : options[value]?.value
      : undefined;
  const children = [];

  for (let i = 0; i < options.length; i++) {
    children.push(
      <Option
        key={i}
        value={typeof options[i] === "string" ? options[i] : (options[i] as any).value}
      >
        {typeof options[i] === "string" ? options[i] : (options[i] as any).label}
      </Option>
    );
  }

  function handleChange(value: string, option: any) {
    if (onChange) {
      if (option?.key) {
        onChange(name, parseInt(option.key), value);
      } else {
        onChange(name, -1, undefined);
      }
    }
  }

  return (
    <Select
      size={size || "large"}
      value={val}
      onChange={handleChange}
      onFocus={onFocus}
      disabled={disabled}
      placeholder={placeholder}
      style={{ width: "100%" }}
      {...rest}
    >
      {children}
    </Select>
  );
};

export default SelectComponent;
