import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { Loading } from "src/components/atoms/displays";
import { Container } from "src/components/atoms/grid";
import { Writing3 } from "src/components/atoms/texts/writing";
import { useDispenseContext } from "../../../contexts/dispense/DispenseContext";
import { useMachineContext } from "../../../contexts/machine/MachineContext";
import {
  IGetAllQueryParams,
  TRANSACTION_TYPE,
  getAllQueryStatus,
  transactionTypes,
} from "../../../types/dispense";
import { Gap } from "../../atoms/spaces";
import StyledPagination from "../common/StyledPagination";
import { DispenseStatusText } from "./common";
import {
  DATA_RANGE_PERIOD,
  DATA_RANGE_PERIOD_LABEL,
  dataRangePeriods,
  TDataRangePeriod,
} from "../../../types/overview/enum";
import dayjs, { Dayjs } from "dayjs";
import { Select } from "../../atoms/selects";
import { DateRangepicker } from "../../atoms/dates";
import { useAdminAuthContext } from "../../../contexts/admin/AuthContext";
import { SummaryTable } from "../common/tables";

interface IProps {
  pageSize: number;
  institutionId: string;
}

const RFID: React.FC<IProps> = ({ pageSize, institutionId }) => {
  const { adminAuth } = useAdminAuthContext();
  const { dispenses, count, dispensesLoading, getAllDispensesApiAction } = useDispenseContext();
  const { machines, machinesLoading } = useMachineContext();

  const [apiParams, setApiParams] = useState<IGetAllQueryParams>({
    page: 1,
    limit: pageSize,
    startDate: moment().subtract(1, "months").startOf("day").toDate(),
    endDate: moment().endOf("day").toDate(),
    status: adminAuth?.admin.type === "SUPER" ? "ALL" : "SUCCESSFUL",
    transactionType: "ALL",
    machineId: undefined,
  });
  const [rangePeriods, setRangePeriods] = useState<TDataRangePeriod | undefined>("last_30_days");

  useEffect(() => {
    getAllDispensesApiAction(institutionId, apiParams);
  }, [getAllDispensesApiAction, institutionId, apiParams]);

  const handleChangePage = (pageNo: number) => {
    setApiParams((prev) => ({ ...prev, page: pageNo }));
  };

  const setDateFilters = useCallback((from: Dayjs, to: Dayjs) => {
    setApiParams((prev) => ({
      ...prev,
      max: false,
      startDate: from.startOf("day").toDate(),
      endDate: to.endOf("day").toDate(),
    }));
  }, []);

  const onChangePeriod = useCallback(
    (name: string | undefined, key: number, val?: string) => {
      const value = val as unknown as TDataRangePeriod;

      setRangePeriods(value);
      const today = dayjs();

      switch (value) {
        case DATA_RANGE_PERIOD.LAST_30_DAYS:
          setDateFilters(today.subtract(30, "day"), today);
          break;

        case DATA_RANGE_PERIOD.LAST_3_MONTHS:
          setDateFilters(today.subtract(3, "month"), today);
          break;

        case DATA_RANGE_PERIOD.LAST_6_MONTHS:
          setDateFilters(today.subtract(6, "month"), today);
          break;

        case DATA_RANGE_PERIOD.LAST_YEAR:
          setDateFilters(today.subtract(1, "year"), today);
          break;

        case DATA_RANGE_PERIOD.LAST_2_YEARS:
          setDateFilters(today.subtract(2, "year"), today);
          break;

        case DATA_RANGE_PERIOD.THIS_MONTH:
          setDateFilters(today.startOf("month"), today);
          break;

        case DATA_RANGE_PERIOD.THIS_YEAR:
          setDateFilters(today.startOf("year"), today);
          break;

        case DATA_RANGE_PERIOD.MAXIMUM:
          setApiParams((prev) => ({
            ...prev,
            max: true,
            startDate: undefined,
            endDate: undefined,
          }));
          break;

        default:
          setApiParams((prev) => ({
            ...prev,
            max: undefined,
            startDate: undefined,
            endDate: undefined,
          }));
          break;
      }
    },
    [setDateFilters]
  );

  return (
    <Container>
      {/* Filters */}
      <Container display="flex" flexFlow="column" gap="0.5rem">
        <Container display="flex" flexFlow="row" gap="1rem">
          <Container width="200px">
            <Select
              size="large"
              placeholder="Machine"
              allowClear
              loading={machinesLoading}
              value={machines.findIndex((mac) => mac._id === apiParams.machineId)}
              options={machines.map((mac) => ({ value: mac._id, label: mac.physicalId }))}
              onChange={(n, k, v) => setApiParams((prev) => ({ ...prev, machineId: v }))}
            />
          </Container>
          <Container>
            <DateRangepicker
              size="large"
              allowClear={false}
              onChange={(n, nd, nds) => {
                setRangePeriods(undefined);
                if (nds) {
                  setApiParams((prev) => ({
                    ...prev,
                    max: false,
                    startDate: nds[0] ? dayjs(nds[0]).startOf("day").toDate() : undefined,
                    endDate: nds[1] ? dayjs(nds[1]).endOf("day").toDate() : undefined,
                  }));
                } else {
                  setApiParams((prev) => ({
                    ...prev,
                    max: false,
                    startDate: undefined,
                    endDate: undefined,
                  }));
                }
              }}
              values={[moment(apiParams.startDate), moment(apiParams.endDate)]}
            />
          </Container>
          <Container width="200px">
            <Select
              size="large"
              placeholder="Period"
              value={dataRangePeriods.findIndex((d) => d === rangePeriods)}
              options={dataRangePeriods.map((rp) => ({
                label: DATA_RANGE_PERIOD_LABEL[rp],
                value: rp,
              }))}
              onChange={onChangePeriod}
              allowClear
            />
          </Container>
        </Container>

        <Container display="flex" flexFlow="row" gap="1rem">
          <Container width="200px">
            <Select
              size="large"
              placeholder="Transaction type"
              value={transactionTypes.findIndex((tt) => tt === apiParams.transactionType)}
              options={transactionTypes.map((tt) => ({
                label: tt,
                value: tt,
              }))}
              onChange={(n, k, v) =>
                setApiParams((prev) => ({ ...prev, transactionType: v as any }))
              }
              allowClear
            />
          </Container>
          <Container width="200px">
            <Select
              size="large"
              placeholder="Status"
              value={getAllQueryStatus.findIndex((st) => st === apiParams.status)}
              options={getAllQueryStatus.map((st) => ({
                label: st,
                value: st,
              }))}
              onChange={(n, k, v) => setApiParams((prev) => ({ ...prev, status: v as any }))}
            />
          </Container>
        </Container>
      </Container>
      <Gap height="1rem" />

      <SummaryTable>
        <thead>
          <th>
            <Writing3>Machine</Writing3>
          </th>

          <th>
            <Writing3>Payment Type</Writing3>
          </th>

          <th>
            <Writing3>RFID</Writing3>
          </th>
          <th>
            <Writing3>Time</Writing3>
          </th>

          <th>
            <Writing3>Status</Writing3>
          </th>

          {apiParams.status !== "SUCCESSFUL" ? (
            <>
              <th>
                <Writing3>Failure Reason</Writing3>
              </th>
              <th>
                <Writing3>Payment failure</Writing3>
              </th>
            </>
          ) : null}
        </thead>
        {!dispensesLoading ? (
          <>
            <tbody>
              {dispenses.map((dispense) => (
                <tr key={dispense._id}>
                  <td>
                    <Writing3>{dispense.machineId}</Writing3>
                  </td>
                  <td>
                    <Writing3>
                      {dispense.transactionType}
                      {dispense.transactionType === TRANSACTION_TYPE.BKASH
                        ? dispense.paid
                          ? " (Paid)"
                          : ""
                        : ""}
                    </Writing3>
                  </td>
                  <td>
                    <Writing3>{dispense.rfid}</Writing3>
                  </td>
                  <td>
                    <Writing3>{moment(dispense.dispensedAt).format("YYYY-MM-DD hh:mm A")}</Writing3>
                  </td>
                  <td>
                    <DispenseStatusText success={dispense.isSuccessful}>
                      {dispense.isSuccessful ? "Successful" : "Failed"}
                    </DispenseStatusText>
                  </td>

                  {apiParams.status !== "SUCCESSFUL" ? (
                    <>
                      <td>
                        <Writing3>{dispense.failureReason}</Writing3>
                      </td>
                      <td>
                        <Writing3>{dispense.paymentFailure}</Writing3>
                      </td>
                    </>
                  ) : null}
                </tr>
              ))}
            </tbody>
          </>
        ) : null}
      </SummaryTable>

      {!dispensesLoading ? (
        <>
          <Gap height="1.5rem" />
          <StyledPagination
            total={count}
            pageSize={pageSize}
            defaultCurrent={1}
            current={apiParams.page}
            onChange={handleChangePage}
            showLessItems={true}
            align={"center"}
          />
        </>
      ) : (
        <Container display="flex" alignItems="center" justifyContent="center">
          <Loading size={"60px"} />
        </Container>
      )}
    </Container>
  );
};

export default RFID;
