import { AxiosResponse } from "axios";
import { apiRequest } from "../apiRequest";
import { GENERATE_REPORT_API_URL } from "./reportApiUrl";
import { TGenerateReportFilter, TGenerateReportResponse } from "../../types/report";

export function generateReportApi(queryParams: TGenerateReportFilter) {
  return new Promise<AxiosResponse<TGenerateReportResponse>>((resolve, reject) => {
    apiRequest().get(GENERATE_REPORT_API_URL, { params: queryParams }).then(resolve).catch(reject);
  });
}
