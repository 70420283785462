import { AxiosResponse } from "axios";
import { apiRequest } from "../apiRequest";
import {
  GET_OVERVIEW_BAR_CHART_API_URL,
  GET_OVERVIEW_SUMMARY_API_URL,
  GET_OVERVIEW_PIE_CHART_API_URL,
} from "./overviewApiUrl";
import {
  TOverviewBarChartQuery,
  TOverviewBarChartResponse,
  TOverviewPieChartResponse,
  TOverviewSummaryResponse,
} from "../../types/overview";

export function fetchDataForBarChartApi(queryParams: TOverviewBarChartQuery) {
  return new Promise<AxiosResponse<TOverviewBarChartResponse>>((resolve, reject) => {
    apiRequest()
      .get(GET_OVERVIEW_BAR_CHART_API_URL, { params: queryParams })
      .then(resolve)
      .catch(reject);
  });
}

export function fetchDataForPieChartApi(queryParams: TOverviewBarChartQuery) {
  return new Promise<AxiosResponse<TOverviewPieChartResponse>>((resolve, reject) => {
    apiRequest()
      .get(GET_OVERVIEW_PIE_CHART_API_URL, { params: queryParams })
      .then(resolve)
      .catch(reject);
  });
}

export function fetchOverviewSummaryApi(queryParams: TOverviewBarChartQuery) {
  return new Promise<AxiosResponse<TOverviewSummaryResponse>>((resolve, reject) => {
    apiRequest()
      .get(GET_OVERVIEW_SUMMARY_API_URL, { params: queryParams })
      .then(resolve)
      .catch(reject);
  });
}
