import { AxiosResponse } from "axios";
import { IPaymentGatewayDetails, IPaymentGatewayResponse } from "src/types/paymentGateway";
import { apiRequest } from "../apiRequest";
import {
  createOrUpdatePaymentGateway,
  deletePaymentGateway,
  getPaymentGateway,
} from "./paymentGatewayApiUrl";

export function getPaymentGatewayApi(institutionId: string) {
  return new Promise<AxiosResponse<IPaymentGatewayResponse>>((resolve, reject) => {
    apiRequest().get(getPaymentGateway(institutionId)).then(resolve).catch(reject);
  });
}

export function createOrUpdatePaymentGatewayApi(
  institutionId: string,
  data: IPaymentGatewayDetails
) {
  return new Promise<AxiosResponse<IPaymentGatewayResponse>>((resolve, reject) => {
    apiRequest()
      .put(createOrUpdatePaymentGateway(institutionId), { ...data, institutionId })
      .then(resolve)
      .catch(reject);
  });
}

export function deletePaymentGatewayApi(institutionId: string) {
  return new Promise<AxiosResponse<void>>((resolve, reject) => {
    apiRequest().delete(deletePaymentGateway(institutionId)).then(resolve).catch(reject);
  });
}
