import React, { useCallback, useEffect, useState } from "react";
import { Heading1, Heading2, Heading3 } from "../../atoms/texts/heading";
import { Bar, Doughnut } from "react-chartjs-2";
import { theme } from "../../../assets/styles/styled-theme";
import { Gap } from "../../atoms/spaces";
import {
  fetchDataForBarChartApi,
  fetchDataForPieChartApi,
  fetchOverviewSummaryApi,
} from "../../../api/overview/overviewApi";
import { TTransactionType } from "../../../types/dispense";
import {
  TOverviewBarChartQuery,
  TOverviewBarChartSingleData,
  TOverviewPieChartResponse,
  TOverviewSummaryResponse,
} from "../../../types/overview";
import { DateRangepicker } from "../../atoms/dates";
import { Container } from "../../atoms/grid";
import dayjs, { Dayjs } from "dayjs";
import {
  DATA_FREQUENCY,
  DATA_RANGE_PERIOD,
  DATA_RANGE_PERIOD_LABEL,
  dataFrequencies,
  dataRangePeriods,
  TDataRangePeriod,
} from "../../../types/overview/enum";
import { Select } from "../../atoms/selects";
import { firstLetterToUpperCase } from "../../../utils/strings";
import moment from "moment";
import { Writing1, Writing3 } from "../../atoms/texts/writing";
import { useInstitutionsContext } from "../../../contexts/institution/InstitutionsContext";
import { Empty } from "antd";
import { SummaryTable } from "../common/tables";

const PIE_COLORS: Record<TTransactionType, string> = {
  BKASH: "#ff157a",
  CASH: "#21da00",
  RFID: "#ff5f17",
};

const DEFAULT_FILTERS: TOverviewBarChartQuery = {
  dateStart: dayjs().subtract(30, "day").startOf("day").toDate(),
  dateEnd: dayjs().endOf("day").toDate(),
  frequency: DATA_FREQUENCY.DAILY,
};

type TProps = {
  organizationId: string;
};

const OrganizationAdminOverview: React.FC<TProps> = ({ organizationId }) => {
  const { institutions, institutionsLoading } = useInstitutionsContext();

  const [filters, setFilters] = useState<TOverviewBarChartQuery>({
    ...DEFAULT_FILTERS,
    organizationId,
  });
  const [rangePeriods, setRangePeriods] = useState<TDataRangePeriod | undefined>("last_30_days");

  const [barChartData, setBarChartData] = useState<TOverviewBarChartSingleData[]>([]);
  const [pieChartData, setPieChartData] = useState<TOverviewPieChartResponse>([]);
  const [summary, setSummary] = useState<TOverviewSummaryResponse>();

  const fetchDataForBarChartApiAction = useCallback(async () => {
    const { data: barChart } = await fetchDataForBarChartApi(filters);
    setBarChartData(barChart.data);
  }, [filters]);

  const fetchDataForPieChartApiAction = useCallback(async () => {
    const { data: pieChart } = await fetchDataForPieChartApi(filters);
    setPieChartData(pieChart.sort((a) => (a._id ? -1 : 1)));
  }, [filters]);

  const fetchOverviewSummaryApiAction = useCallback(async () => {
    const { data: summaries } = await fetchOverviewSummaryApi(filters);
    setSummary(summaries);
  }, [filters]);

  useEffect(() => {
    fetchDataForBarChartApiAction();
  }, [fetchDataForBarChartApiAction]);

  useEffect(() => {
    fetchDataForPieChartApiAction();
  }, [fetchDataForPieChartApiAction]);

  useEffect(() => {
    fetchOverviewSummaryApiAction();
  }, [fetchOverviewSummaryApiAction]);

  const setDateFilters = useCallback((from: Dayjs, to: Dayjs) => {
    setFilters((prev) => ({
      ...prev,
      max: false,
      dateStart: from.startOf("day").toDate(),
      dateEnd: to.endOf("day").toDate(),
    }));
  }, []);

  const onChangePeriod = useCallback(
    (name: string | undefined, key: number, val?: string) => {
      const value = val as unknown as TDataRangePeriod;

      setRangePeriods(value);
      const today = dayjs();

      switch (value) {
        case DATA_RANGE_PERIOD.LAST_30_DAYS:
          setDateFilters(today.subtract(30, "day"), today);
          break;

        case DATA_RANGE_PERIOD.LAST_3_MONTHS:
          setDateFilters(today.subtract(3, "month"), today);
          break;

        case DATA_RANGE_PERIOD.LAST_6_MONTHS:
          setDateFilters(today.subtract(6, "month"), today);
          break;

        case DATA_RANGE_PERIOD.LAST_YEAR:
          setDateFilters(today.subtract(1, "year"), today);
          break;

        case DATA_RANGE_PERIOD.LAST_2_YEARS:
          setDateFilters(today.subtract(2, "year"), today);
          break;

        case DATA_RANGE_PERIOD.THIS_MONTH:
          setDateFilters(today.startOf("month"), today);
          break;

        case DATA_RANGE_PERIOD.THIS_YEAR:
          setDateFilters(today.startOf("year"), today);
          break;

        case DATA_RANGE_PERIOD.MAXIMUM:
          setFilters((prev) => ({
            ...prev,
            max: true,
            dateStart: undefined,
            dateEnd: undefined,
          }));
          break;

        default:
          setFilters((prev) => ({
            ...prev,
            max: undefined,
            dateStart: DEFAULT_FILTERS.dateStart,
            dateEnd: DEFAULT_FILTERS.dateEnd,
          }));
          break;
      }
    },
    [setDateFilters]
  );

  return (
    <>
      <Heading1>Welcome to VMS organization - admin dashboard</Heading1>
      <Gap height="0.75rem" />

      <Container display="flex" flexFlow="row" justifyContent="space-between" flexWrap="wrap">
        <Container display="flex" flexFlow="row" gap="1rem">
          <Container width="200px">
            <Select
              size="large"
              placeholder="Institution"
              allowClear
              loading={institutionsLoading}
              value={institutions.findIndex((inst) => inst._id === filters.institutionId)}
              options={institutions.map((inst) => ({ value: inst._id, label: inst.name }))}
              onChange={(n, k, v) => setFilters((prev) => ({ ...prev, institutionId: v }))}
            />
          </Container>
          <Container>
            <DateRangepicker
              size="large"
              allowClear={false}
              onChange={(n, nd, nds) => {
                setRangePeriods(undefined);
                if (nds) {
                  setFilters((prev) => ({
                    ...prev,
                    max: false,
                    dateStart: nds[0] ? dayjs(nds[0]).startOf("day").toDate() : undefined,
                    dateEnd: nds[1] ? dayjs(nds[1]).endOf("day").toDate() : undefined,
                  }));
                } else {
                  setFilters((prev) => ({
                    ...prev,
                    max: false,
                    dateStart: undefined,
                    dateEnd: undefined,
                  }));
                }
              }}
              values={[moment(filters.dateStart), moment(filters.dateEnd)]}
            />
          </Container>
          <Container width="200px">
            <Select
              size="large"
              placeholder="Period"
              value={dataRangePeriods.findIndex((d) => d === rangePeriods)}
              options={dataRangePeriods.map((rp) => ({
                label: DATA_RANGE_PERIOD_LABEL[rp],
                value: rp,
              }))}
              onChange={onChangePeriod}
              allowClear
            />
          </Container>
        </Container>

        <Container width="200px">
          <Select
            size="large"
            placeholder="Frequency"
            value={dataFrequencies.findIndex((d) => d === filters.frequency)}
            options={dataFrequencies.map((freq) => ({
              value: freq,
              label: firstLetterToUpperCase(freq),
            }))}
            onChange={(n, k, v) => {
              setFilters((prev) => ({ ...prev, frequency: v as any }));
            }}
          />
        </Container>
      </Container>

      <Gap height="1rem" />

      <Container display="flex" flexWrap="wrap-reverse">
        <Container flex={2}>
          <Heading2>Dispense types</Heading2>
          {pieChartData.length ? (
            <Doughnut
              data={{
                labels: pieChartData.map((p: any) => p._id ?? "Other"),
                datasets: [
                  {
                    data: pieChartData.map((p: any) => p.total),
                    backgroundColor: pieChartData.map(
                      (p: any) => PIE_COLORS[p._id as never] ?? "#ffd919"
                    ),
                  },
                ],
              }}
              options={{
                tooltips: {
                  callbacks: {
                    label: function (tooltipItem: any) {
                      const total = pieChartData.reduce((prev, curr) => prev + curr.total, 0);
                      const type = pieChartData[tooltipItem.index] || "";

                      if (type) {
                        const label = `${type._id}: ${type.total} (${(
                          (type.total / total) *
                          100
                        ).toFixed(2)}%)`;
                        return label;
                      }

                      return ": ";
                    },
                  },
                },
              }}
            />
          ) : (
            <Empty />
          )}
        </Container>

        <Container flex={3}>
          <Heading2>Dispenses over time</Heading2>
          <Bar
            data={{
              labels: barChartData.map((d: any) => d._id),
              datasets: [
                {
                  label: "Success",
                  data: barChartData.map((d: any) => d.successCount),
                  borderWidth: 1,
                  backgroundColor: theme.colors.successColor,
                  hoverBackgroundColor: theme.colors.successDark,
                  borderColor: theme.colors.successDark,
                },
              ],
            }}
            options={{
              responsive: true,
              scales: {
                xAxes: [{ stacked: true }],
                yAxes: [{ stacked: true }],
              },
            }}
          />
        </Container>
      </Container>
      <Gap height="3rem" />

      <Container display="flex" alignItems="flex-start" gap="1rem" flexWrap="wrap">
        <Container flex={2}>
          <SummaryTable>
            <thead>
              <tr>
                <th colSpan={2}>
                  <Heading2>Stock update summary</Heading2>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <Writing3>Total updates</Writing3>
                </td>
                <td>
                  <Writing3>{summary?.stockSummary.totalLogs}</Writing3>
                </td>
              </tr>
              <tr>
                <td>
                  <Writing3>Opening stock</Writing3>
                </td>
                <td>
                  <Writing3>{summary?.stockSummary.openingValue}</Writing3>
                </td>
              </tr>
              <tr>
                <td>
                  <Writing3>Closing stock</Writing3>
                </td>
                <td>
                  <Writing3>{summary?.stockSummary.closingValue}</Writing3>
                </td>
              </tr>
            </tbody>
          </SummaryTable>
        </Container>

        <Container flex={3}>
          <SummaryTable>
            <thead>
              <tr>
                <th colSpan={4}>
                  <Heading2>Dispense summary</Heading2>
                </th>
              </tr>
              <tr>
                <th>
                  <Heading3>Type</Heading3>
                </th>
                <th>
                  <Heading3>Successful dispenses</Heading3>
                </th>
                <th>
                  <Heading3>Paid amount (BDT)</Heading3>
                </th>
                <th>
                  <Heading3>Remaining stock</Heading3>
                </th>
              </tr>
            </thead>
            <tbody>
              {summary?.dispenseSummary.transactionTypes.map((type, index) => {
                return (
                  <tr key={type._id}>
                    <td>
                      <Writing3>{type._id}</Writing3>
                    </td>
                    <td>
                      <Writing3>{type.totalSuccess ?? 0}</Writing3>
                    </td>
                    <td>
                      <Writing3>{type.totalPaidAmount ?? 0}</Writing3>
                    </td>
                    {index === 0 ? (
                      <td rowSpan={(summary?.dispenseSummary.transactionTypes.length ?? 0) + 1}>
                        <Writing1>{summary?.dispenseSummary?.remainingStock ?? 0}</Writing1>
                      </td>
                    ) : null}
                  </tr>
                );
              })}
              <tr>
                <td>
                  <Heading3>Total</Heading3>
                </td>
                <td colSpan={2}>
                  <Heading3>{summary?.dispenseSummary?.totalSuccess}</Heading3>
                </td>
              </tr>
            </tbody>
          </SummaryTable>
        </Container>
      </Container>
    </>
  );
};

export default OrganizationAdminOverview;
