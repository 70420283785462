/* eslint-disable no-console */
import { Button, Select, Space, Switch } from "antd";
import React, { useState } from "react";
import { SolidButton } from "src/components/atoms/buttons";
import { Loading, Status } from "src/components/atoms/displays";
import { ColumnContainer, Container, RowContainer } from "src/components/atoms/grid";
import { Gap } from "src/components/atoms/spaces";
import { Writing1, Writing2, Writing3 } from "src/components/atoms/texts/writing";
import { TextFieldForm } from "src/components/molecules/inputfields";
import { useMachineContext } from "src/contexts/machine/MachineContext";
import { IMachine, IMachineCreateUpdate, IStock } from "src/types/machine";
import { firstLetterToUpperCase } from "src/utils/strings";
import { validateMachineCreate, validateMachineUpdate } from "src/validators/machineValidator";
import styled from "styled-components";
import { Message } from "../../molecules/texts/message";
import {
  CheckIcon,
  DeleteIcon,
  EditIcon,
  FlexContainer,
  ListDiv,
  ListItem,
  PlusIcon,
  StopIcon,
  StyledDeleteModal,
  StyledModal,
  TBody,
  THead,
  THeader,
} from "./common";

const AddStockRow = styled.div<{ isDelete: boolean }>`
  display: grid;
  grid-template-columns: ${({ isDelete }) =>
    isDelete ? "1fr 1fr 1fr 1fr 0.125fr" : "1fr 1fr 1fr 1fr "};
  column-gap: 1rem;
  margin-bottom: 1rem;
`;

const DeleteStock = styled.div`
  padding-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
`;

const initialStockData = { rowNo: 1, columnNo: 1, sizeId: "", productCount: 0 };

const initialInputData: IMachineCreateUpdate = {
  name: "",
  physicalId: "",
  stocks: [initialStockData],
  price: 10,
  point: undefined,
  isActivated: true,
  pointsEnabled: false,
};

interface IProps {
  institutionId: string;
}

const Machines: React.FC<IProps> = ({ institutionId }) => {
  const {
    machinesLoading,
    machinesChangeLoading,
    machines,
    createMachineApiAction,
    updateMachineApiAction,
    deleteMachineApiAction,
  } = useMachineContext();

  const [modal, setModal] = useState<{
    visible: boolean;
    data?: IMachine;
    type: "create" | "update" | "update stock" | "delete" | "activate" | "deactivate" | undefined;
  }>({ visible: false, data: undefined, type: undefined });

  const [inputData, setInputData] = useState<IMachineCreateUpdate>(initialInputData);

  const [error, setError] = useState<any>({});

  const handleModalClose = () => {
    setModal({ visible: false, type: undefined });
    setInputData(initialInputData);
    setError({});
  };

  const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    setInputData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleAddStock = () => {
    setInputData((prev) => {
      const newObj = { ...prev };
      const newStockArr = [...newObj.stocks];
      if (newStockArr.length)
        newStockArr.push({
          rowNo: newStockArr[newStockArr.length - 1].rowNo,
          columnNo: newStockArr[newStockArr.length - 1].columnNo + 1,
          sizeId: "",
          productCount: 0,
        });
      else newStockArr.push(initialStockData);
      newObj.stocks = newStockArr;
      return { ...newObj };
    });
  };

  const handleChangeStockData = (index: number, name: keyof IStock, value: any) => {
    setInputData((prev) => {
      const newObj = { ...prev };
      const newStockArr = [...newObj.stocks];
      newStockArr[index] = { ...newStockArr[index], [name]: value };
      newObj.stocks = newStockArr;
      return { ...newObj };
    });
  };

  const handleDeleteStock = (index: number) => {
    setInputData((prev) => {
      const newObj = { ...prev };
      const newStockArr = [...newObj.stocks];
      newStockArr.splice(index, 1);
      newObj.stocks = newStockArr;
      return { ...newObj };
    });
  };

  const handleCreateModal = () => {
    setModal({ visible: true, type: "create" });
  };

  const onCreateMachine = async () => {
    const { isValid, errors } = await validateMachineCreate(inputData);
    if (!isValid && errors) {
      setError(errors);
      console.log(errors);
      return;
    }
    setError({});

    await createMachineApiAction(institutionId, inputData);
    handleModalClose();
  };

  const handleUpdateModal = (machine: IMachine) => {
    setModal({ visible: true, data: machine, type: "update" });
    setInputData({
      name: machine.name,
      physicalId: machine.physicalId,
      stocks: machine.stocks,
      price: machine.price,
      point: machine.point,
      isActivated: !!machine.isActivated,
      pointsEnabled: !!machine.pointsEnabled,
    });
  };

  const handleUpdateStockModal = (machine: IMachine) => {
    setModal({ visible: true, data: machine, type: "update stock" });
    setInputData({
      name: machine.name,
      physicalId: machine.physicalId,
      stocks: machine.stocks,
      price: machine.price,
      point: machine.point,
      isActivated: !!machine.isActivated,
      pointsEnabled: !!machine.pointsEnabled,
    });
  };

  const onUpdateMachine = async () => {
    if (!modal.data) return;

    const { isValid, errors } = await validateMachineUpdate(inputData);
    if (!isValid && errors) {
      setError(errors);
      console.log(errors);
      return;
    }
    setError({});

    await updateMachineApiAction(institutionId, modal.data._id, inputData);
    handleModalClose();
  };

  const handleDeleteModal = (data: IMachine) => {
    setModal({ visible: true, data: data, type: "delete" });
  };

  const onDeleteMachine = async () => {
    if (modal.data?._id) {
      await deleteMachineApiAction(institutionId, modal.data._id);
      handleModalClose();
    }
  };

  const handleActivationModal = (machine: IMachine, type: "activate" | "deactivate") => {
    setModal({ visible: true, data: machine, type: type });
    setInputData({
      name: machine.name,
      physicalId: machine.physicalId,
      stocks: machine.stocks,
      price: machine.price,
      isActivated: type === "activate",
      pointsEnabled: machine.pointsEnabled,
    });
  };

  const handleToggle = (n: keyof IMachineCreateUpdate, bool: boolean) => {
    setInputData((prev) => ({ ...prev, [n]: bool }));
  };

  return (
    <Container>
      <StyledModal
        title={
          modal.type === "update stock"
            ? "Update Stock: " + inputData.physicalId
            : firstLetterToUpperCase(modal.type) + " Machine"
        }
        visible={
          modal.visible &&
          (modal.type === "create" || modal.type === "update" || modal.type === "update stock")
        }
        confirmLoading={machinesChangeLoading}
        onOk={
          modal.type === "create"
            ? () => onCreateMachine()
            : modal.type === "update"
            ? () => onUpdateMachine()
            : modal.type === "update stock"
            ? () => onUpdateMachine()
            : () => null
        }
        onCancel={handleModalClose}
        okText={firstLetterToUpperCase(modal.type)}
      >
        <>
          {modal.type !== "update stock" ? (
            <>
              <Writing1>Machine Name</Writing1>
              <TextFieldForm
                placeholder="Name"
                name="name"
                value={inputData.name}
                onChange={handleChangeName}
                errorMessage={error.name}
              />
              <Gap height={"1rem"} />
              <Writing1>Physical ID</Writing1>
              <TextFieldForm
                placeholder="Physical ID"
                name="physicalId"
                value={inputData.physicalId}
                onChange={handleChangeName}
                disabled={modal.type === "update"}
                errorMessage={error.physicalId}
              />
              <Gap height={"1rem"} />
              <ToggleContainer>
                <Writing1>Points enabled</Writing1>
                <Switch
                  checked={inputData.pointsEnabled}
                  onChange={(bool) => handleToggle("pointsEnabled", bool)}
                />
              </ToggleContainer>
              <Gap height={"1rem"} />
              <RowContainer>
                <ColumnContainer>
                  <Writing1>Product Price (Tk)</Writing1>
                  <TextFieldForm
                    type="number"
                    placeholder="price"
                    name="price"
                    value={inputData.price}
                    onChange={handleChangeName}
                    errorMessage={error.price}
                  />
                </ColumnContainer>
                <Gap width="1rem" />
                <ColumnContainer>
                  <Writing1>Product Point</Writing1>
                  <TextFieldForm
                    type="number"
                    placeholder="point"
                    name="point"
                    disabled={!inputData.pointsEnabled}
                    value={inputData.point}
                    onChange={handleChangeName}
                    errorMessage={error.point}
                  />
                </ColumnContainer>
              </RowContainer>
              <Gap height={"1rem"} />
            </>
          ) : null}

          {inputData.stocks.map((stock, index) => (
            <AddStockRow key={index.toString()} isDelete={modal.type !== "update stock"}>
              <Container>
                <Writing3>Row no.</Writing3>
                <TextFieldForm
                  placeholder="Row number"
                  type="number"
                  name="rowNo"
                  value={stock.rowNo}
                  onChange={(e) =>
                    handleChangeStockData(
                      index,
                      "rowNo",
                      !e.target.value
                        ? undefined
                        : Number(e.target.value) < 0
                        ? 0
                        : Number(e.target.value)
                    )
                  }
                  errorMessage={error[`stocks[${index}].rowNo`]}
                  disabled={modal.type === "update stock"}
                />
              </Container>
              <Container>
                <Writing3>Column no.</Writing3>
                <TextFieldForm
                  placeholder="Column number"
                  type="number"
                  name="columnNo"
                  value={stock.columnNo}
                  onChange={(e) =>
                    handleChangeStockData(
                      index,
                      "columnNo",
                      !e.target.value
                        ? undefined
                        : Number(e.target.value) < 0
                        ? 0
                        : Number(e.target.value)
                    )
                  }
                  errorMessage={error[`stocks[${index}].columnNo`]}
                  disabled={modal.type === "update stock"}
                />
              </Container>
              <Container>
                <Writing3>Size type</Writing3>
                <Select
                  placeholder="Size"
                  size="large"
                  style={{ width: "100%", marginBottom: "0.2rem" }}
                  value={stock.sizeId}
                  onChange={(val) =>
                    handleChangeStockData(
                      index,
                      "sizeId",
                      machines
                        .find((m) => m.physicalId === inputData.physicalId)
                        ?.sizeTypes.find((s) => s.sizeId === val)?.sizeId
                    )
                  }
                  allowClear
                  disabled={modal.type === "create"}
                >
                  {machines
                    .find((m) => m.physicalId === inputData.physicalId)
                    ?.sizeTypes.map((size) => (
                      <Select.Option key={size.sizeId} value={size.sizeId}>
                        {size.sizeName}
                      </Select.Option>
                    ))}
                </Select>
                {error[`stocks[${index}].size`] ? (
                  <Message type="error" message={error[`stocks[${index}].size`]} />
                ) : null}
              </Container>
              <Container>
                <Writing3>Stock</Writing3>
                <TextFieldForm
                  type="number"
                  placeholder="Count"
                  name="productCount"
                  value={stock.productCount}
                  onChange={(e) =>
                    handleChangeStockData(
                      index,
                      "productCount",
                      !e.target.value
                        ? undefined
                        : Number(e.target.value) < 0
                        ? 0
                        : Number(e.target.value)
                    )
                  }
                  disabled={modal.type === "create" || modal.type === "update"}
                  errorMessage={error[`stocks[${index}].productCount`]}
                />
              </Container>
              {modal.type !== "update stock" ? (
                <DeleteStock>
                  <DeleteIcon onClick={() => handleDeleteStock(index)} />
                </DeleteStock>
              ) : null}
            </AddStockRow>
          ))}
          {modal.type === "update stock" ? (
            <>
              <TextFieldForm
                labelText="Assign to"
                placeholder="Assign task"
                name="taskAssignedTo"
                value={inputData.taskAssignedTo}
                onChange={handleChangeName}
                errorMessage={error.taskAssignedTo}
              />
            </>
          ) : (
            <Button type="primary" onClick={handleAddStock}>
              Add New Row or Column
            </Button>
          )}
        </>
      </StyledModal>

      <StyledDeleteModal
        visible={
          (modal.visible && modal.type === "delete") ||
          modal.type === "activate" ||
          modal.type === "deactivate"
        }
        title={firstLetterToUpperCase(modal.type) + " Machine"}
        onOk={
          modal.type === "delete"
            ? () => onDeleteMachine()
            : modal.type === "activate" || modal.type === "deactivate"
            ? () => onUpdateMachine()
            : () => null
        }
        okText={firstLetterToUpperCase(modal.type)}
        onCancel={handleModalClose}
        confirmLoading={machinesChangeLoading}
      >
        <Writing2>
          Are you sure you want to {modal.type} this machine "{modal.data?.physicalId}"?
        </Writing2>
      </StyledDeleteModal>

      <FlexContainer>
        <SolidButton onClick={() => handleCreateModal()}>Add Machine</SolidButton>
      </FlexContainer>

      <Gap height={"1.5rem"} />

      {!machinesLoading ? (
        <Container>
          <THeader columns={6}>
            <THead>
              <Writing3>Physical ID</Writing3>
            </THead>
            <THead>
              <Writing3>Name</Writing3>
            </THead>
            <THead>
              <Writing3>Product Price (Tk)</Writing3>
            </THead>
            <THead>
              <Writing3>Product Point</Writing3>
            </THead>
            <THead>
              <Writing3>Product Count</Writing3>
            </THead>
            <THead>
              <Writing3>Actions</Writing3>
            </THead>
          </THeader>

          <TBody>
            {machines.map((machine) => (
              <ListItem key={machine._id} columns={6}>
                <ListDiv>
                  <Status
                    label={machine.physicalId}
                    title={machine.isActivated ? "Active" : "Inactive"}
                    status={machine.isActivated ? "running" : "expired"}
                  />
                </ListDiv>
                <ListDiv>
                  <Writing3>{machine.name}</Writing3>
                </ListDiv>
                <ListDiv>
                  <Writing3>{machine.price}</Writing3>
                </ListDiv>
                <ListDiv>
                  <Writing3>{machine.point}</Writing3>
                </ListDiv>
                <ListDiv>
                  <Writing3>
                    {machine.stocks.reduce((prev, current) => prev + current.productCount, 0)}
                  </Writing3>
                  <PlusIcon onClick={() => handleUpdateStockModal(machine)} />
                </ListDiv>
                <ListDiv>
                  <Space size={"large"} align={"center"}>
                    <EditIcon onClick={() => handleUpdateModal(machine)} />
                    <DeleteIcon onClick={() => handleDeleteModal(machine)} />
                    {machine.isActivated ? (
                      <StopIcon
                        title="Deactivate machine"
                        onClick={() => handleActivationModal(machine, "deactivate")}
                      />
                    ) : (
                      <CheckIcon
                        title="Activate machine"
                        onClick={() => handleActivationModal(machine, "activate")}
                      />
                    )}
                  </Space>
                </ListDiv>
              </ListItem>
            ))}
          </TBody>
        </Container>
      ) : (
        <Loading size={"60px"} />
      )}
    </Container>
  );
};

export default Machines;
