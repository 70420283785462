import CheckCircleOutlined from "@ant-design/icons/lib/icons/CheckCircleOutlined";
import DeleteOutlined from "@ant-design/icons/lib/icons/DeleteOutlined";
import EditOutlined from "@ant-design/icons/lib/icons/EditOutlined";
import PlusSquareFilled from "@ant-design/icons/lib/icons/PlusSquareFilled";
import StopOutlined from "@ant-design/icons/lib/icons/StopOutlined";
import { Modal } from "antd";
import styled from "styled-components";
import { Writing3 } from "../../atoms/texts/writing";

export const DispenseStatusText = styled(Writing3)<{ success?: boolean }>`
  color: ${({ theme, success }) =>
    success ? theme.colors.successColor : theme.colors.dangerColor};
`;

export const THeader = styled.div<{ columns: number }>`
  display: grid;
  grid-template-columns: ${({ columns }) => Array(columns).fill("1fr").join(" ")};
  background-color: ${({ theme }) => theme.colors.backgroundColor};
`;

export const THead = styled.div<{ clickable?: any }>`
  padding: 0.75rem 1rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  column-gap: 0.5rem;
  border: 0.5px solid ${({ theme }) => theme.colors.borderColor};
  cursor: ${({ clickable }) => (clickable ? "pointer" : undefined)};

  &:nth-child(n + 2) {
    border-left: none;
  }
`;

export const TBody = styled.div``;

export const ListDiv = styled(THead)`
  border-top: none;
`;

export const ListItem = styled(THeader)`
  background-color: ${({ theme }) => theme.colors.bodyColor};
`;

export const EditIcon = styled(EditOutlined)`
  cursor: pointer;
  svg {
    color: ${({ theme }) => theme.colors.successColor};
    width: 20px;
    height: 20px;
  }
`;

export const PlusIcon = styled(PlusSquareFilled)`
  cursor: pointer;
  svg {
    color: ${({ theme }) => theme.colors.secondaryColor};
    width: 20px;
    height: 20px;
  }
`;

export const DeleteIcon = styled(DeleteOutlined)`
  cursor: pointer;
  svg {
    color: ${({ theme }) => theme.colors.dangerColor};
    width: 20px;
    height: 20px;
  }
`;

export const StopIcon = styled(StopOutlined)`
  cursor: pointer;
  svg {
    color: ${({ theme }) => theme.colors.dangerDark};
    width: 20px;
    height: 20px;
  }
`;

export const CheckIcon = styled(CheckCircleOutlined)`
  cursor: pointer;
  svg {
    color: ${({ theme }) => theme.colors.successDark};
    width: 20px;
    height: 20px;
  }
`;

export const StyledModal = styled(Modal)`
  .ant-modal-footer {
    padding: 0.75rem;
  }
`;

export const StyledDeleteModal = styled(Modal)`
  .ant-modal-content {
    .ant-modal-footer {
      padding: 0.75rem;
    }
  }
`;

export const FlexContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const FlexContainerSpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
