import { Button } from "antd";
import React, { useCallback } from "react";
import styled from "styled-components";
import { Heading2 } from "../../atoms/texts/heading";
import { Writing2 } from "../../atoms/texts/writing";
import DivaLogo from "../../../assets/images/diva.png";
import SuccessIcon from "../../../assets/images/success_icon.png";
import { Gap } from "../../atoms/spaces";
import { theme } from "../../../assets/styles/styled-theme";
import { Writing5 } from "../../atoms/texts/writing";
import { BASE_URL } from "../../../utils/config";

const MainContainer = styled.div`
  width: 600px;
  max-width: 100%;
  height: calc(100vh - 60px);
  margin-left: auto;
  margin-right: auto;
  padding: 1rem 1rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${Heading2}, ${Writing2}, ${Writing5} {
    text-align: center;
  }
`;

const TopContainer = styled.div`
  flex: 1;
`;

const BottomContainer = styled.div`
  padding-top: 0.5rem;
  border-top: 2px solid ${({ theme }) => theme.colors.gray4};
`;

const LogoImage = styled.img`
  width: 100px;
  max-width: 80%;
  max-height: 120px;
  object-fit: contain;
`;

const StatusImage = styled.img`
  width: 90px;
  object-fit: contain;
`;

const MessageContainer = styled.div`
  width: 280px;
`;

const DispenseButton = styled(Button)`
  border-radius: 2px;

  &,
  &:hover,
  &:focus {
    border-color: ${({ theme }) => theme.colors.divaColor};
    color: ${({ theme }) => theme.colors.divaColor};
  }
`;

const PaymentStatusSuccessPage: React.FC = (props) => {
  const params = new URLSearchParams((props as any).location.search);
  const machineId = params.get("machineId");

  const onDispenseAnotherClick = useCallback(() => {
    const replaceUrl = `${BASE_URL}/api/v1/payment/bKash-pgw?machineId=${machineId}`;
    location.replace(replaceUrl);
  }, [machineId]);

  return (
    <MainContainer>
      <TopContainer>
        <LogoImage src={DivaLogo} />
        <Gap height="3rem" />

        <StatusImage src={SuccessIcon} />

        <Gap height="3rem" />
        <MessageContainer>
          <Heading2 color={theme.colors.primaryColor}>bKash Payment Successful!</Heading2>
          <Gap height="0.5rem" />
          <Writing2>Thank you for using Diva, we value your hygiene.</Writing2>
          <Gap height="2.5rem" />

          <DispenseButton size="large" onClick={onDispenseAnotherClick}>
            Dispense another pad
          </DispenseButton>
        </MessageContainer>
      </TopContainer>

      <BottomContainer>
        <Writing5>
          Made with ❤️ by&nbsp;
          <a href="https://headless.ltd/" target="__blank" rel="noreferrer">
            Headless Technologies Limited
          </a>
          &nbsp; in collaboration with bKash.
        </Writing5>
      </BottomContainer>
    </MainContainer>
  );
};

export default PaymentStatusSuccessPage;
