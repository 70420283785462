export const DATA_FREQUENCY = {
  DAILY: "daily" as const,
  MONTHLY: "monthly" as const,
  YEARLY: "yearly" as const,
};
export const dataFrequencies = Object.values(DATA_FREQUENCY);
export type TDataFrequency = typeof dataFrequencies[number];

export const DATA_RANGE_PERIOD = {
  LAST_30_DAYS: "last_30_days" as const,
  LAST_3_MONTHS: "last_3_months" as const,
  LAST_6_MONTHS: "last_6_months" as const,
  LAST_YEAR: "last_year" as const,
  LAST_2_YEARS: "last_2_years" as const,
  THIS_MONTH: "this_month" as const,
  THIS_YEAR: "this_year" as const,
  MAXIMUM: "maximum" as const,
};
export const dataRangePeriods = Object.values(DATA_RANGE_PERIOD);
export type TDataRangePeriod = typeof dataRangePeriods[number];
export const DATA_RANGE_PERIOD_LABEL = {
  [DATA_RANGE_PERIOD.LAST_30_DAYS]: "Last 30 days",
  [DATA_RANGE_PERIOD.LAST_3_MONTHS]: "Last 3 months",
  [DATA_RANGE_PERIOD.LAST_6_MONTHS]: "Last 6 months",
  [DATA_RANGE_PERIOD.LAST_YEAR]: "Last year",
  [DATA_RANGE_PERIOD.LAST_2_YEARS]: "Last 2 years",
  [DATA_RANGE_PERIOD.THIS_MONTH]: "This month",
  [DATA_RANGE_PERIOD.THIS_YEAR]: "This year",
  [DATA_RANGE_PERIOD.MAXIMUM]: "Maximum",
};
