import { AxiosResponse } from "axios";
import { apiRequest } from "../apiRequest";
import {
  createInstitution,
  deleteInstitution,
  getAllInstitutions,
  getInstitutionById,
  updateInstitution,
} from "./institutionApiUrl";
import { IInstitution } from "src/types/institution";

export function getAllInstitutionsApi(organizationId: string) {
  return new Promise<AxiosResponse<IInstitution[]>>((resolve, reject) => {
    apiRequest()
      .get(getAllInstitutions(organizationId))
      .then((resp) => {
        resolve(resp);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function getInstitutionByIdApi(institutionId: string) {
  return new Promise<AxiosResponse<IInstitution>>((resolve, reject) => {
    apiRequest()
      .get(getInstitutionById(institutionId))
      .then((resp) => {
        resolve(resp);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function createInstitutionApi(organizationId: string, createData: FormData) {
  return new Promise<AxiosResponse<IInstitution>>((resolve, reject) => {
    apiRequest()
      .post(createInstitution(organizationId), createData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((resp) => {
        resolve(resp);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function updateInstitutionApi(institutionId: string, updateData: FormData) {
  return new Promise<AxiosResponse<IInstitution>>((resolve, reject) => {
    apiRequest()
      .put(updateInstitution(institutionId), updateData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((resp) => {
        resolve(resp);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

interface IDeleteResponse {
  message: string;
}

export function deleteInstitutionApi(institutionId: string) {
  return new Promise<AxiosResponse<IDeleteResponse>>((resolve, reject) => {
    apiRequest()
      .delete(deleteInstitution(institutionId))
      .then((resp) => {
        resolve(resp);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
