import { Tabs } from "antd";
import React, { useEffect } from "react";
import styled from "styled-components";
// import { Heading1 } from "src/components/atoms/texts/heading";
import Description from "src/components/organisms/common/Description";
import Machines from "src/components/organisms/institutionAdminDashboard/Machines";
import RFID from "src/components/organisms/institutionAdminDashboard/RFID";
import Admins from "src/components/organisms/organizationAdminDashboard/Admins";
import { useAdminAuthContext } from "src/contexts/admin/AuthContext";
import { useInstitutionsContext } from "src/contexts/institution/InstitutionsContext";
import { useMachineContext } from "src/contexts/machine/MachineContext";
import { useRFIDContext } from "src/contexts/rfid/RFIDContext";
import Dispenses from "../../organisms/institutionAdminDashboard/Dispenses";
// import { useDispenseContext } from "../../../contexts/dispense/DispenseContext";
import { ReloadOutlined } from "@ant-design/icons";
import moment from "moment";
import { BorderedButton } from "src/components/atoms/buttons";
import ProductSize from "src/components/organisms/institutionAdminDashboard/ProductSize";
import { FlexContainerSpaceBetween } from "src/components/organisms/institutionAdminDashboard/common";
import { useDispenseContext } from "src/contexts/dispense/DispenseContext";
import InstitutionAdminOverview from "../../organisms/institutionAdminDashboard/InstitutionAdminOverview";
import PaymentGateway from "../../organisms/organizationAdminDashboard/PaymentGateway";
import { useOrganizationsContext } from "../../../contexts/organization/OrganizationsContext";
import InstitutionReport from "../../organisms/institutionAdminDashboard/InstitutionReport";

const { TabPane } = Tabs;

const Container = styled.div`
  width: 100%;
`;

const PaddedTabPane = styled(TabPane)`
  padding-right: 1.5rem;
  padding-left: 1.5rem;

  @media (max-width: 768px) {
    padding-right: 0;
    padding-left: 0;
  }
`;

const InstitutionAdminPage: React.FC = (props: any) => {
  const pageSize = 15;
  const {
    currentInstitution,
    institutionAdmins,
    clearCurrentInstitution,
    getAllInstitutionAdminApiAction,
    getInstitutionByIdApiAction,
  } = useInstitutionsContext();

  const { currentOrganization } = useOrganizationsContext();

  const { getAllRFIDApiAction } = useRFIDContext();
  const { getAllMachinesApiAction } = useMachineContext();
  const { getAllDispensesApiAction } = useDispenseContext();

  const { adminAuth } = useAdminAuthContext();

  const institutionId = props.match.params.institutionId;

  const refreshInstitutionAndDispenses = () => {
    clearCurrentInstitution();
    getAllDispensesApiAction(institutionId, {
      page: 1,
      limit: pageSize,
      startDate: moment().subtract(1, "months").startOf("day").toDate(),
      endDate: moment().endOf("day").toDate(),
      status: "ALL",
      transactionType: "ALL",
      machineId: undefined,
    });
  };

  useEffect(() => {
    if (institutionId) {
      getInstitutionByIdApiAction(institutionId);
      getAllMachinesApiAction(institutionId);
      getAllRFIDApiAction(institutionId);
      // getAllDispensesApiAction(institutionId, 1, pageSize);
    }
    if (adminAuth?.admin.type === "SUPER" || adminAuth?.admin.type === "ORGANIZATION") {
      getAllInstitutionAdminApiAction(institutionId);
    }
  }, [
    institutionId,
    adminAuth,
    getInstitutionByIdApiAction,
    getAllInstitutionAdminApiAction,
    getAllMachinesApiAction,
    getAllRFIDApiAction,
    // getAllDispensesApiAction,
  ]);

  return (
    <Container>
      <FlexContainerSpaceBetween>
        <Description type="INSTITUTION" institution={currentInstitution} />
        <BorderedButton onClick={() => refreshInstitutionAndDispenses()} size="middle">
          <ReloadOutlined />
          Refresh
        </BorderedButton>
      </FlexContainerSpaceBetween>

      <Tabs type={"card"} defaultActiveKey={"1"} size={"large"}>
        <PaddedTabPane tab={"Overview"} key={"1"}>
          {currentOrganization ? (
            <InstitutionAdminOverview
              organizationId={currentOrganization._id}
              institutionId={institutionId}
            />
          ) : null}
        </PaddedTabPane>

        <PaddedTabPane tab={"Machines"} key={"2"}>
          <Machines institutionId={institutionId} />
        </PaddedTabPane>

        <PaddedTabPane tab={"RFIDs"} key={"3"}>
          <RFID institutionId={institutionId} />
        </PaddedTabPane>

        <PaddedTabPane tab={"Dispenses"} key={"4"}>
          <Dispenses institutionId={institutionId} pageSize={pageSize} />
        </PaddedTabPane>

        <PaddedTabPane tab={"Product Sizes"} key={"5"}>
          <ProductSize />
        </PaddedTabPane>

        {currentInstitution ? (
          <PaddedTabPane tab="Report" key="6">
            <InstitutionReport
              organizationId={currentInstitution?.organizationId}
              institutionId={institutionId}
            />
          </PaddedTabPane>
        ) : null}

        {adminAuth?.admin.type === "SUPER" || adminAuth?.admin.type === "ORGANIZATION" ? (
          <PaddedTabPane tab={"Admins"} key={"7"}>
            <Admins admins={institutionAdmins} institutionId={institutionId} type={"INSTITUTION"} />
          </PaddedTabPane>
        ) : null}

        <PaddedTabPane tab={"Payment gateway"} key={"8"}>
          <PaymentGateway institutionId={institutionId} />
        </PaddedTabPane>
      </Tabs>
    </Container>
  );
};

export default InstitutionAdminPage;
