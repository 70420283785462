import styled from "styled-components";

export const SummaryTable = styled.table`
  width: 100%;
  border-collapse: collapse;

  th,
  td {
    border: 1px solid ${({ theme }) => theme.colors.borderColor};
    padding: 8px;
    text-align: left;
  }

  th {
    background-color: ${({ theme }) => theme.colors.backgroundColor};
    font-weight: bold;
    font-size: 14px;
  }

  tbody tr:nth-child(odd) {
    background-color: #fafafa;
  }

  tbody tr:nth-child(even) {
    background-color: #ffffff;
  }

  tbody tr:hover {
    background-color: #eeeeee;
  }

  thead {
    display: table-header-group;
  }

  tfoot {
    display: table-footer-group;
  }

  table,
  th,
  td {
    page-break-inside: auto;
  }
`;
