import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import { ICommonApiError } from "src/api/apiRequest";
import { handlePrivateApiError } from "src/api/errorHandlers";
import {
  createOrUpdatePaymentGatewayApi,
  deletePaymentGatewayApi,
  getPaymentGatewayApi,
} from "src/api/paymentGateway/paymentGatewayApi";
import { SolidButton } from "src/components/atoms/buttons";
import { Loading } from "src/components/atoms/displays";
import { Container } from "src/components/atoms/grid";
import { TextFieldForm, PasswordFieldForm } from "src/components/molecules/inputfields";
import { useAdminAuthContext } from "src/contexts/admin/AuthContext";
import { showErrorToastAction, showToastAction } from "src/contexts/toast";
import { IPaymentGatewayDetails, IPaymentGatewayResponse } from "src/types/paymentGateway";
import styled from "styled-components";
import { RadioInput } from "src/components/atoms/inputs";
import { validatePaymentGateway } from "src/validators/paymentGatewayValidator";
import { Modal } from "antd";

const RadioBoxContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;

const RadioBoxLabel = styled.label`
  font-size: 1rem;
  margin: 0.5rem 0;
  cursor: pointer;
`;

const SubmitButton = styled(SolidButton)`
  padding: 0 1.5rem;
  margin: 0.5rem 0;
`;

const FormContainer = styled.div`
  margin: 1rem 0 0.5rem 0;
  width: 75%;

  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const StyledDeleteModal = styled(Modal)`
  .ant-modal-content {
    .ant-modal-footer {
      padding: 0.75rem;
    }
  }
`;

interface IProps {
  institutionId: string;
}

const initialFormData: IPaymentGatewayDetails = {
  username: "",
  password: "",
  appKey: "",
  appSecret: "",
};

const PaymentGateway: React.FC<IProps> = ({ institutionId }: IProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isFetchLoading, setIsFetchLoading] = useState<boolean>(false);
  const [isMyPaymentGateway, setIsMyPaymentGateway] = useState<boolean | null>(null);
  const [paymentGatewayDetails, setPaymentGatewayDetails] =
    useState<IPaymentGatewayResponse | null>(null);
  const [formData, setFormData] = useState<IPaymentGatewayDetails>({ ...initialFormData });
  const [inputError, setInputError] = useState<Record<string, string>>({});
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState<boolean>(false);

  const { logoutAdminApiAction } = useAdminAuthContext();

  const fetchPaymentGateway = useCallback(
    async (isLoading = true) => {
      setIsFetchLoading(isLoading);

      try {
        const { data } = await getPaymentGatewayApi(institutionId);
        setPaymentGatewayDetails({ ...data });
        setFormData({ ...initialFormData, username: data.username });
        setIsMyPaymentGateway(true);
      } catch (err: any) {
        const { data, error, status } = handlePrivateApiError(
          err as ICommonApiError,
          logoutAdminApiAction
        );

        if (status === 404) {
          setIsMyPaymentGateway(false);
        } else {
          showErrorToastAction({
            message: data?.message || error || "Error while fetching payment gateway information",
          });
        }
      } finally {
        setIsFetchLoading(false);
      }
    },
    [logoutAdminApiAction, institutionId]
  );

  const onFormDataChangeHandler = (e: ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.target;

    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));

    setInputError((prev) => ({
      ...prev,
      [name]: "",
    }));
  };

  useEffect(() => {
    setIsLoading(true);
    fetchPaymentGateway();
    setIsLoading(false);
  }, [fetchPaymentGateway]);

  const deletePaymentGatewayApiAction = useCallback(
    async (isLoading = true) => {
      setIsDeleteLoading(isLoading);
      try {
        await deletePaymentGatewayApi(institutionId);
        setPaymentGatewayDetails(null);
        setFormData({ ...initialFormData });
        setIsMyPaymentGateway(false);
        showToastAction({
          type: "success",
          message: "The payment gateway has been removed",
        });
      } catch (err) {
        const { data, error } = handlePrivateApiError(err as ICommonApiError, logoutAdminApiAction);
        showErrorToastAction({
          message: data?.message || error || "Could not delete the payment gateway credentials",
        });
      } finally {
        setIsDeleteModalOpen(false);
        setIsDeleteLoading(false);
      }
    },
    [logoutAdminApiAction, institutionId]
  );

  const onSaveHandler = async (isLoading = true) => {
    setIsFetchLoading(isLoading);

    if (isMyPaymentGateway) {
      // Saving my payment gateway
      const { errors, isValid } = await validatePaymentGateway(formData);
      if (isValid) {
        try {
          const { data } = await createOrUpdatePaymentGatewayApi(institutionId, formData);
          setPaymentGatewayDetails({ ...data });
          setFormData({ ...initialFormData, username: data.username });
          setIsMyPaymentGateway(true);
          showToastAction({
            type: "success",
            message: "Successfully added the payment gateway",
          });
        } catch (err) {
          const { data, error, status } = handlePrivateApiError(
            err as ICommonApiError,
            logoutAdminApiAction
          );
          if (status === 400) {
            showErrorToastAction({
              message: "Invalid payment gateway credentials",
            });
          } else {
            showErrorToastAction({
              message: data?.message || error || "Could not save the payment gateway credentials",
            });
          }
        } finally {
          setIsFetchLoading(false);
        }
      } else {
        setInputError((prev) => ({ ...prev, ...errors }));
        setIsFetchLoading(false);
      }
    } else {
      if (paymentGatewayDetails) {
        // If paymentGatewayDetails available then open the delete modal and delete it if the user click on the confirm button
        setIsFetchLoading(false);
        setIsDeleteModalOpen(true);
      } else {
        // Reset the form data
        setPaymentGatewayDetails(null);
        setFormData({ ...initialFormData });
        setIsMyPaymentGateway(false);
        setIsFetchLoading(false);
      }
    }
  };

  return (
    <Container>
      {!isLoading && isMyPaymentGateway !== null ? (
        <>
          <Container>
            <RadioBoxContainer>
              <RadioBoxLabel onClick={() => setIsMyPaymentGateway(false)}>
                <RadioInput checked={!isMyPaymentGateway} /> Diva payment gateway
              </RadioBoxLabel>
            </RadioBoxContainer>
            <RadioBoxContainer>
              <RadioBoxLabel onClick={() => setIsMyPaymentGateway(true)}>
                <RadioInput checked={isMyPaymentGateway} /> My bKash payment gateway
              </RadioBoxLabel>
            </RadioBoxContainer>
            {isMyPaymentGateway ? (
              <FormContainer>
                <Container>
                  <TextFieldForm
                    labelText="Username"
                    name="username"
                    value={formData.username}
                    onChange={onFormDataChangeHandler}
                    placeholder="Enter your username"
                    errorMessage={inputError.username}
                  />
                </Container>
                <Container>
                  <PasswordFieldForm
                    labelText="Password"
                    name="password"
                    value={formData.password}
                    onChange={onFormDataChangeHandler}
                    placeholder="Enter your password"
                    errorMessage={inputError.password}
                  />
                </Container>
                <Container>
                  <PasswordFieldForm
                    labelText="App Key"
                    name="appKey"
                    value={formData.appKey}
                    onChange={onFormDataChangeHandler}
                    placeholder="Enter your app key"
                    errorMessage={inputError.appKey}
                  />
                </Container>
                <Container>
                  <PasswordFieldForm
                    labelText="App Secret"
                    name="appSecret"
                    value={formData.appSecret}
                    onChange={onFormDataChangeHandler}
                    placeholder="Enter your app secret"
                    errorMessage={inputError.appSecret}
                  />
                </Container>
              </FormContainer>
            ) : null}
            <SubmitButton loading={isFetchLoading} onClick={() => onSaveHandler()}>
              Save
            </SubmitButton>
          </Container>
          <StyledDeleteModal
            confirmLoading={isDeleteLoading}
            onOk={deletePaymentGatewayApiAction}
            title="Delete payment gateway credentials"
            visible={isDeleteModalOpen}
            onCancel={() => setIsDeleteModalOpen(false)}
            okText="Confirm"
            okButtonProps={{ style: { backgroundColor: "crimson" } }}
          >
            Are you sure, you want to delete the payment gateway credentials?
          </StyledDeleteModal>
        </>
      ) : (
        <Loading size="60px" />
      )}
    </Container>
  );
};

export default PaymentGateway;
